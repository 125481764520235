import { GatsbyBrowser } from "gatsby";
import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

import i18nInstance from "@config/i18next";

import { AVAILABLE_LANGS } from "@typings/globals";

import DefaultTheme from "@ui/themes/default";

import { DialogManager } from "@features/DialogManager";
import { DrawerManager } from "@features/DrawerManager";

import { langFromTag } from "@services/LangFromTag";

import { OverlayLockScroll } from "@containers/OverlayLockScroll";
import { ToastContainer } from "@containers/Toastify";

import { CountOfNotificationsProvider } from "@hooks/useNumberOfNotifications";
import { AuthProvider } from "@hooks/useAuth";
import { ModalProvider } from "@hooks/modal";
import { BreakpointProvider } from "@hooks/useBreakpoint";
import { PrismicProvider } from "@hooks/usePrismic";

import storeStaticPages from "./src/redux/store";

/**
 * We should refactor Batwork & Batsoft so that they also rely only on `storeStaticPages`.
 * That way we will have much smaller imports.
 */
const getStore = () => {
  if (process.env.ORIGIN === "batmaid") {
    return storeStaticPages;
  }

  console.error("ORIGIN not found, loading fallback store");
  return storeStaticPages;
};

const Providers: GatsbyBrowser<
  Record<string, never>,
  { lang?: string; language?: string; prismicId?: string }
>["wrapPageElement"] = ({ element, props }) => {
  const language =
    langFromTag(props.pageContext.lang || "") ||
    langFromTag(props.pageContext.language || "") ||
    AVAILABLE_LANGS.EN;

  i18nInstance.changeLanguage(language);

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          "data-prismic-document-id": props.pageContext.prismicId,
          lang: language,
        }}
      />
      <Provider store={getStore()}>
        <ThemeProvider theme={DefaultTheme}>
          <ModalProvider>
            <DialogManager.Provider>
              <DrawerManager.Provider>
                <AuthProvider>
                  <CountOfNotificationsProvider>
                    <PrismicProvider>
                      <BreakpointProvider>{element}</BreakpointProvider>
                    </PrismicProvider>
                  </CountOfNotificationsProvider>
                </AuthProvider>

                <OverlayLockScroll />
                <ToastContainer />
              </DrawerManager.Provider>
            </DialogManager.Provider>
          </ModalProvider>
        </ThemeProvider>
      </Provider>
    </React.Fragment>
  );
};

export { Providers };
