export type FeatureTogglesType = Partial<FeatureToggleState>;

export interface FeatureToggleState {
  commitment_plans: boolean;
  commitment_plans_v2: boolean;
  b2b_booking: boolean;
  teaser_version_enabled_prod: boolean;
  teaser_version_enabled_hidden: boolean;
  cleaning_products_sale: boolean;
  more_often_frequency_for_b2c: boolean;
  service_voucher: boolean;

  non_commitment_plans: boolean;
  agent_pwa_profile: boolean;
  agent_pwa_availabilities: boolean;
  agent_pwa_schedule: boolean;
  agent_pwa_my_cleanings: boolean;
  agent_pwa_new_cleanings: boolean;
  agent_cleaning_checkin: boolean;
  agent_unassignment: boolean;
  agent_job_cannot_make_contact: boolean;
  pwa_login_by_apple: boolean;
  pwa_urssaf: boolean;
  telemetry: boolean;
  rescheduling_automation: boolean;
  combined_terms_and_cleaning_materials: boolean;
  gift_cards: boolean;
  agent_replacement_automation: boolean;
  cleaning_checklist: boolean;
  cleaning_recap: boolean;
  pwa_prevent_mixing_clients_b2b_with_b2c: boolean;

  // A / B TESTING
  pwa_google_review_job_rating_ab_test: boolean;

  // HOME CLEANING
  pwa_home_cleaning_simple_frequency_selector_info_label: boolean;
  pwa_home_cleaning_simple_frequency_selector_custom_label: boolean;
  pwa_home_cleaning_price_per_hour: boolean;
  pwa_home_cleaning_extra_services_v2: boolean;
  pwa_home_cleaning_mobile_collapse_extras: boolean;
  pwa_home_cleaning_sign_in_on_the_top: boolean;
  pwa_home_cleaning_splitted_checkout_form: boolean;
  pwa_home_cleaning_checkout_form_alternative_order: boolean;
  pwa_home_cleaning_duration_frequency_alternative_order: boolean;

  // B2B
  pwa_b2b_cleaning_materials_select: boolean;
  company_sdi_code: boolean;
  b2b_price_plans: boolean;

  // EOT
  pwa_eot_splitted_checkout_form: boolean;
  pwa_eot_checkout_form_alternative_order: boolean;

  // GIFT CARDS PAGE
  pwa_gift_cards_flow: boolean;

  // SURVEYS
  pwa_booking_experience_survey: boolean;

  // CLIENT
  pwa_client_payment_method_status_alert: boolean;
  pwa_client_overdue_status_alert: boolean;
  pwa_google_review_job_rating: boolean;

  // AGENT
  web_absence_management: boolean;

  // NEW VERSION OF UI
  pwa_client_profile_v2: boolean;
  pwa_client_dashboard_v2: boolean;

  // WWW
  pwa_help_center: boolean;
  pwa_gift_cards_modal: boolean;
  pwa_trustpilot_widget: boolean;

  // OTHERS
  agent_absence_automation_opt_in: boolean;
  contract_cancellation: boolean;
  cleaning_rating_v2_checklist: boolean;
}

export const defaultFeatureTogglesState: FeatureToggleState = {
  commitment_plans: false,
  b2b_booking: false,
  teaser_version_enabled_prod: false,
  teaser_version_enabled_hidden: false,
  commitment_plans_v2: false,
  cleaning_products_sale: false,
  more_often_frequency_for_b2c: false,
  service_voucher: false,

  non_commitment_plans: false,
  agent_pwa_profile: false,
  agent_pwa_availabilities: false,
  agent_pwa_schedule: false,
  agent_pwa_my_cleanings: false,
  agent_pwa_new_cleanings: false,
  agent_cleaning_checkin: false,
  agent_unassignment: false,
  agent_job_cannot_make_contact: false,
  pwa_login_by_apple: true,
  pwa_urssaf: false,
  telemetry: false,
  rescheduling_automation: false,
  combined_terms_and_cleaning_materials: false,
  gift_cards: false,
  agent_replacement_automation: false,
  cleaning_checklist: false,
  cleaning_recap: false,
  pwa_prevent_mixing_clients_b2b_with_b2c: false,

  // A / B TESTING
  pwa_google_review_job_rating_ab_test: false,

  // HOME CLEANING
  pwa_home_cleaning_simple_frequency_selector_info_label: false,
  pwa_home_cleaning_simple_frequency_selector_custom_label: false,
  pwa_home_cleaning_price_per_hour: false,
  pwa_home_cleaning_extra_services_v2: false,
  pwa_home_cleaning_sign_in_on_the_top: false,
  pwa_home_cleaning_mobile_collapse_extras: false,
  pwa_home_cleaning_splitted_checkout_form: false,
  pwa_home_cleaning_checkout_form_alternative_order: false,
  pwa_home_cleaning_duration_frequency_alternative_order: false,

  // B2B
  pwa_b2b_cleaning_materials_select: false,
  company_sdi_code: false,
  b2b_price_plans: false,

  // EOT
  pwa_eot_splitted_checkout_form: false,
  pwa_eot_checkout_form_alternative_order: false,

  // GIFT CARDS PAGE
  pwa_gift_cards_flow: false,

  // SURVEYS
  pwa_booking_experience_survey: false,

  // CLIENT
  pwa_client_payment_method_status_alert: false,
  pwa_client_overdue_status_alert: false,
  pwa_google_review_job_rating: false,

  // AGENT
  web_absence_management: false,

  // NEW VERSION OF UI
  pwa_client_profile_v2: false,
  pwa_client_dashboard_v2: false,

  // WWW
  pwa_help_center: false,
  pwa_gift_cards_modal: false,
  pwa_trustpilot_widget: false,

  // OTHERS
  agent_absence_automation_opt_in: false,
  contract_cancellation: false,
  cleaning_rating_v2_checklist: false,
};

export interface FeatureTogglesSaved {
  updated: number; // timestamp
  data: Partial<FeatureToggleState>;
}

export type FeatureToggleKey = keyof FeatureToggleState;
