import i18next from "i18next";
import AsyncBackend from "i18next-async-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { AVAILABLE_LANGS } from "@typings/globals";

import { appTranslations } from "@translations/translations.app";

const getTranslationDomains = (): string[] => {
  const batmaidDomains = [
    "batmaid_pwa_booking",
    "batmaid_pwa_client_options",
    "batmaid_pwa_client_notifications",
    "batmaid_pwa_generic",
    "batmaid_pwa_holidays",
    "batmaid_pwa_my_cleanings",
    "batmaid_pwa_navigation",
    "batmaid_pwa_password",
    "batmaid_pwa_apply",
    "batmaid_pwa_bookings_to_validate",
    "batmaid_pwa_end_of_tenancy",
    "batmaid_pwa_b2b",
    "batmaid_pwa_profile",
    "batmaid_pwa_landing_page",
    "batmaid_pwa_onboarding",
    "batmaid_pwa_rating",
    "batmaid_pwa_urssaf",
    "batmaid_pwa_help_center",
    "batmaid_pwa_checklist",
    "batmaid_pwa_wrong_service_type",
    "batmaid_pwa_gift_cards",
    "batmaid_pwa_payment_gateway",
    "batmaid_pwa_static_homepage",

    // surveys
    "batmaid_pwa_booking_experience_survey",
  ];

  const agentDomains = [
    "batmaid_pwa_generic",
    "batmaid_pwa_navigation",
    "batmaid_pwa_apply",
    "batmaid_pwa_b2b",
    "batmaid_pwa_profile",
    "batmaid_pwa_agent_profile",
    "batmaid_pwa_agent_schedule",
    "batmaid_pwa_agent_cleanings",
    "batmaid_pwa_agent_availabilities",
    "batmaid_pwa_agent_onboarding",
    "batmaid_pwa_agent_login",
    "batmaid_pwa_rating",
  ];

  if (process.env.NODE_ENV === "test") {
    return [...batmaidDomains, ...agentDomains];
  }

  if (process.env.APP_NAME === "agentApp") {
    return agentDomains;
  }

  if (process.env.APP_NAME === "app" || process.env.ORIGIN === "batmaid") {
    return batmaidDomains;
  }

  return [];
};

const resolvedTranslations = getTranslationDomains();

const importNamespace = (namespace: string, language: string) => {
  return import(`./../__preloaded_translations/${language}/${namespace}.json`);
};

const loadTranslations = (language: AVAILABLE_LANGS) => {
  const namespaces: any = {};

  getTranslationDomains().forEach(namespace => {
    namespaces[namespace] = () => importNamespace(namespace, language);
  });

  return namespaces;
};

const resources = {
  en: loadTranslations(AVAILABLE_LANGS.EN),
  fr: loadTranslations(AVAILABLE_LANGS.FR),
  pl: loadTranslations(AVAILABLE_LANGS.PL),
  de: loadTranslations(AVAILABLE_LANGS.DE),
  it: loadTranslations(AVAILABLE_LANGS.IT),
  nl: loadTranslations(AVAILABLE_LANGS.NL),
};

/**
 * Load static translations for local development and unit-tests
 */
let staticResources: { en: any } | undefined;

if (process.env.NODE_ENV !== "production") {
  staticResources = {
    en: {
      ...appTranslations,
    },
  };
}

const createI18nInstance = () => {
  const i18nInstance = i18next.createInstance();

  i18nInstance
    .use(LanguageDetector)
    .use(AsyncBackend)
    .use(initReactI18next)
    .init({
      resources: staticResources,
      returnObjects: true,
      cleanCode: true,
      backend: { resources },
      ns: resolvedTranslations,
      defaultNS: "generic",
      nsSeparator: ".",
      keySeparator: false,
      fallbackLng: DEFAULT_LANGUAGE,
      load: "languageOnly",
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
        bindI18n: "languageChanged",
      },
    });

  return i18nInstance;
};

const i18nInstance = createI18nInstance();

export default i18nInstance;
