import React from "react";

import { IS_BROWSER, IS_TEST } from "@config/consts";

import { mediaQueries, mediaQueriesV2 } from "@ui/themes/default";

import { BreakpointStateV1, BreakpointStateV2 } from "./useBreakpoint.types";
import {
  defaultOpts,
  defaultValueV1,
  defaultValueV2,
  BreakpointContext,
} from "./useBreakpoint.context";

interface Props {
  children: React.ReactNode;
}

export const BreakpointProvider = (props: Props): React.ReactElement => {
  const [queryMatchV1, setQueryMatchV1] = React.useState({ ...defaultValueV1 });
  const [queryMatchV2, setQueryMatchV2] = React.useState({ ...defaultValueV2 });
  const [opts, setOpts] = React.useState({ ...defaultOpts });

  React.useEffect(() => {
    const mediaQueryLists: { [key: string]: MediaQueryList } = {};
    const keysV1 = Object.keys(mediaQueries) as Array<keyof BreakpointStateV1>;
    const keysV2 = Object.keys(mediaQueriesV2) as Array<
      keyof BreakpointStateV2
    >;
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatchesV1 = keysV1.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {} as Record<keyof BreakpointStateV1, boolean>);
      const updatedMatchesV2 = keysV2.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {} as Record<keyof BreakpointStateV2, boolean>);

      setQueryMatchV1(updatedMatchesV1);
      setQueryMatchV2(updatedMatchesV2);
      setOpts({
        ...opts,
        isBreakpointResolved: true,
      });
    };

    if (IS_BROWSER && !IS_TEST && window.matchMedia) {
      const matchesV1: Partial<BreakpointStateV1> = {};
      const matchesV2: Partial<BreakpointStateV2> = {};

      keysV1.forEach(media => {
        if (typeof mediaQueries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(mediaQueries[media]);
          matchesV1[media] = mediaQueryLists[media].matches;
        } else {
          matchesV1[media] = false;
        }
      });
      keysV2.forEach(media => {
        if (typeof mediaQueriesV2[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(mediaQueriesV2[media]);
          matchesV2[media] = mediaQueryLists[media].matches;
        } else {
          matchesV2[media] = false;
        }
      });

      setQueryMatchV1(matchesV1 as BreakpointStateV1);
      setQueryMatchV2(matchesV2 as BreakpointStateV2);
      setOpts({
        ...opts,
        isBreakpointResolved: true,
      });
      isAttached = true;

      keysV1.forEach(media => {
        if (typeof mediaQueries[media] === "string") {
          try {
            mediaQueryLists[media].addEventListener(
              "change",
              handleQueryListener,
            );
          } catch {
            // old versions of Safari
            mediaQueryLists[media].addListener(handleQueryListener);
          }
        }
      });

      keysV2.forEach(media => {
        if (typeof mediaQueriesV2[media] === "string") {
          try {
            mediaQueryLists[media].addEventListener(
              "change",
              handleQueryListener,
            );
          } catch {
            // old versions of Safari
            mediaQueryLists[media].addListener(handleQueryListener);
          }
        }
      });
    }

    return () => {
      if (isAttached) {
        keysV1.forEach(media => {
          if (typeof mediaQueries[media] === "string") {
            const fn =
              mediaQueryLists[media].removeEventListener ||
              mediaQueryLists[media].removeListener;
            fn("change", handleQueryListener);
          }
        });
        keysV2.forEach(media => {
          if (typeof mediaQueriesV2[media] === "string") {
            const fn =
              mediaQueryLists[media].removeEventListener ||
              mediaQueryLists[media].removeListener;
            fn("change", handleQueryListener);
          }
        });
      }
    };
  }, [mediaQueries, mediaQueriesV2]);

  return (
    <BreakpointContext.Provider
      value={{ v1: { ...queryMatchV1 }, v2: { ...queryMatchV2 }, ...opts }}
    >
      {props.children}
    </BreakpointContext.Provider>
  );
};
