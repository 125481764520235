/**
 * @author tomekbuszewski & jakubmaslon
 * @since 2019-6-24
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { rem, transition } from "@ui/helpers";
import { FormError } from "@ui/Atoms/Form/FormError";
import { Label, LabelWrapper } from "@ui/Atoms/Form/Label";
import { ITheme } from "@ui/themes/ThemeInterface";
import { ExtendedInputProps } from "@ui/Atoms/Form/Form.types";
import theme from "@ui/themes/default";
import { Eye } from "@ui/Assets/Symbolicons/Eye";
import { EyeOff } from "@ui/Assets/Symbolicons/EyeOff";

interface Props {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  children?: React.ReactNode;
  theme: ITheme;
  textCenter?: boolean;
  isHidden?: boolean;
  icon?: React.ReactNode;
  isLabelFloating?: boolean;
  isLabelOnTop?: boolean;
  hotjarAllow?: boolean;
}

export const inputStyles = css<Props & { label?: string; errors?: string }>`
  position: relative;
  transition: ${transition(["border"])};
  width: 100%;
  min-height: ${props =>
    props.as === "textarea" ? rem(112) : props.theme.buttons.height};

  display: block;
  flex: content;
  padding-left: ${props => props.theme.margins.base};
  padding-right: ${props =>
    props.icon ? props.theme.margins.base_x5 : props.theme.margins.base};

  padding: ${props =>
      props.as === "textarea" && props.isLabelOnTop
        ? props.theme.margins.base_x2
        : props.theme.margins.base}
    ${props => props.theme.margins.base};

  ${props =>
    props.isLabelFloating &&
    `
    padding-top: ${props.theme.margins.base_x2};
  `}

  color: ${props => props.theme.palette.grey800};
  -webkit-text-fill-color: ${props => props.theme.palette.grey800};
  background: ${props => props.theme.palette.white};
  border: ${rem(1)} solid
    ${props =>
      props.errors ? props.theme.palette.red700 : props.theme.palette.grey100};
  border-radius: ${props => props.theme.buttons.borderRadius};
  outline: 0;

  font-size: ${props => props.theme.fonts.sizes.body};
  text-align: ${props => (props.textCenter ? "center" : "auto")};
  appearance: none;

  &:hover {
    border-color: ${props =>
      props.errors ? props.theme.palette.red700 : props.theme.palette.grey600};
  }

  &::placeholder {
    color: ${props => props.theme.palette.grey600};
    -webkit-text-fill-color: ${props => props.theme.palette.grey600};
  }

  &:focus,
  &:active {
    outline: 0;

    border-color: ${props =>
      props.errors ? props.theme.palette.red700 : props.theme.palette.blue500};
  }

  &:disabled {
    background: ${props => props.theme.palette.grey20};

    &:hover,
    &:focus,
    &:active {
      border-color: ${props => props.theme.palette.grey100};
    }
  }

  ${props =>
    props.isHidden &&
    `
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    transform: scale(0);
  `}
`;

const PristineInput = styled.input<Props>`
  ${inputStyles};
`;

const Input = (props: ExtendedInputProps): React.ReactElement => {
  const [inputType, setInputType] = React.useState<string | undefined>(
    props.type,
  );
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const isInputNotEmpty = !!(props.value && String(props.value).length > 0);
  const isLabelOnTop = isFocused || isInputNotEmpty;

  if (props.label) {
    return (
      <React.Fragment>
        <LabelWrapper
          isLabelFloating={props.isLabelFloating}
          isLabelOnTop={isLabelOnTop}
        >
          <Label
            className="input-label"
            htmlFor={props.name}
            forTextArea={props.as === "textarea"}
            isLabelFloating={props.isLabelFloating}
            isLabelOnTop={isLabelOnTop}
            hasSmallFloatLabel={props.hasSmallFloatLabel}
          >
            {props.label}
          </Label>
          <PristineInputWrapper>
            <PristineInput
              {...props}
              id={props.name}
              ref={props.refElement}
              isLabelOnTop={isLabelOnTop}
              onFocus={() => setIsFocused(true)}
              onBlur={(e: any) => {
                props.onBlur && props.onBlur(e);
                setIsFocused(false);
              }}
              type={inputType}
              data-hj-allow={props.hotjarAllow}
            />
            {props.icon && <Icon className="input-icon">{props.icon}</Icon>}
            {props.type === "password" && (
              <PasswordRevealIcon
                onClick={() =>
                  setInputType(inputType === "password" ? "text" : "password")
                }
              >
                {inputType === "password" ? (
                  <Eye variant="line" size={20} height={20} />
                ) : (
                  <EyeOff variant="line" size={20} height={20} />
                )}
              </PasswordRevealIcon>
            )}
          </PristineInputWrapper>
        </LabelWrapper>
        {props.errors && <FormError>{props.errors}</FormError>}
        {props.optionalLabel && !props.errors && (
          <OptionalLabel>{props.optionalLabel}</OptionalLabel>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PristineInputWrapper style={props.inputWrapperStyles}>
        <PristineInput
          {...props}
          ref={props.refElement}
          isLabelOnTop={isLabelOnTop}
          data-hj-allow={props.hotjarAllow}
        />
        {props.icon && <Icon>{props.icon}</Icon>}
        {props.type === "password" && (
          <PasswordRevealIcon
            onClick={() =>
              setInputType(inputType === "password" ? "text" : "password")
            }
          >
            {inputType === "password" ? (
              <Eye variant="line" size={20} height={20} />
            ) : (
              <EyeOff variant="line" size={20} height={20} />
            )}
          </PasswordRevealIcon>
        )}
      </PristineInputWrapper>
      {props.errors && <FormError>{props.errors}</FormError>}
      {props.optionalLabel && !props.errors && (
        <OptionalLabel>{props.optionalLabel}</OptionalLabel>
      )}
    </React.Fragment>
  );
};

export { Input, PristineInput };

Input.displayName = "Input";

Input.defaultProps = {
  isLabelFloating: true,
};

const PristineInputWrapper = styled.div`
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Icon = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  right: ${theme.margins.base_x2};
  transform: translateY(-50%);
  pointer-events: none;
  align-items: center;
`;

const PasswordRevealIcon = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  right: ${theme.margins.base_x2};
  transform: translateY(-50%);
  align-items: center;
  cursor: pointer;
`;

const OptionalLabel = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.fonts.sizes.caption};
  padding-left: ${props => props.theme.margins.base_x2};
`;
