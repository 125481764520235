export const TRANSLATIONS_ENDPOINT = "/admin/translations";
export const MY_CLEANINGS_ENDPOINT = "/client";
export const SERVICE_LOCATIONS_ENDPOINT =
  "/client/service-locations?zip={zipValue}&serviceType={serviceTypeValue}";
export const SERVICE_LOCATION_BY_ID_ENDPOINT = "/client/service-locations/{id}";
export const LOCATIONS_ENDPOINT = "/client/locations";
export const LOCATION_BY_UUID_ENDPOINT = "/client/locations/{uuid}";
export const LOCATIONS_SET_DEFAULT_ENDPOINT = "/client/profile/main-location";
export const LOCATIONS_UPDATE_PETS_ENDPOINT = "/client/locations/{uuid}/pets";
export const HOLIDAYS_ENDPOINT = "/client/holidays";
export const REFERRAL_ENDPOINT = "/client/referral";
export const UNCOVERED_LOCATIONS_ENDPOINT = "/uncovered-locations";
export const ZIP_SUGGESTION_ENDPOINT =
  "/location/zipcodes?q={qValue}&type={typeValue}";
export const CONTRACTS_TO_PAUSE_ENDPOINT =
  "/client/to-pause/contracts?newHolidaysStartDate={newHolidaysStartDateValue}";

export const PROFILE_PASSWORD_ENDPOINT = "/client/profile/password";
export const PROFILE_BASIC_INFO_ENDPOINT = "/client/profile/basic-info";
export const PROFILE_PAYMENT_ALIASES_ENDPOINT =
  "/client/profile/payment-aliases";
export const PROFILE_ACCOUNT_BALANCE_ENDPOINT =
  "/client/profile/account-balance";
export const PROFILE_DELETE_PAYMENT_ALIAS_ENDPOINT =
  "/client/profile/payment-aliases/{uuid}";
export const PROFILE_DEFAULT_PAYMENT_ALIAS_ENDPOINT =
  "/client/profile/default-payment-alias";
export const PROFILE_COMPANY_INFO_ENDPOINT = "/client/profile/company-info";

export const JOBS2_ENDPOINT = "/client/jobs2";
export const JOB_BY_UUID_ENDPOINT = "/client/jobs/{uuid}";
export const JOBS_KEY_PICK_ENDPOINT = "/client/jobs/{uuid}/key-pick";
export const JOBS_SPECIAL_INSTRUCTIONS_ENDPOINT =
  "/client/jobs/{uuid}/special-instructions";
export const JOBS_RATING_ENDPOINT = "/client/jobs/{uuid}/rating";
export const JOBS_RATING_PICTURES_ENDPOINT =
  "/client/jobs/{uuid}/rating/pictures";
export const DELETE_RATING_PICTURE_ENDPOINT =
  "/client/jobs/{uuid}/rating/pictures/{pictureUuid}";
export const JOBS_CANCELLATION_START_ENDPOINT =
  "/client/cancellation?jobId={uuid}";
export const JOBS_CANCELLATION_PAYMENT_ENDPOINT =
  "/client/cancellation/{cancellationRequestUuid}/payment-method";
export const JOBS_CANCELLATION_REASON_ENDPOINT =
  "/client/cancellation/{cancellationRequestUuid}/reason";
export const INIT_AGENT_REPLACEMENT_ENDPOINT = "/client/jobs/{uuid}/replace";
export const INIT_JOB_RESCHEDULING = "/client/jobs/{uuid}/automatic-reschedule";
export const JOB_RESCHEDULING_AVAILABILITIES =
  "/client/jobs/{uuid}/automatic-reschedule/availabilities";
export const JOB_RESCHEDULING_CHANGE_REQUEST =
  "/client/jobs/{uuid}/automatic-reschedule/change-request";
export const AUTOMATIC_AGENT_REPLACEMENT =
  "/client/jobs/{uuid}/automatic-agent-replacement";
export const REVIEW_INFO_ENDPOINT = "/client/review-info";

export const PROFILE_CONTRACTS = "/client/profile/contracts";
export const PROFILE_CONTRACT = "/client/profile/contracts/{contractUuid}";

export const CONTRACT_CANCELLATION_REASONS =
  "/client/contracts/{contractUuid}/cancellation/reasons";

export const CONTRACT_CANCELLATION =
  "/client/contracts/{contractUuid}/cancellation";

export const CONTRACT_CHECKLIST_ENDPOINT = "/client/contracts/{uuid}/checklist";
export const CONTRACT_ONBOARDING_ENDPOINT =
  "/client/contracts/{uuid}/onboarding";

export const USER_NOTIFICATIONS_ENDPOINT = "/client/notifications";
export const WHOAMI_ENDPOINT = "/whoami";

export const LOGIN_ENDPOINT = "/login";
export const CLIENT_LOGIN_ENDPOINT = "/client/login";
export const AGENT_LOGIN_ENDPOINT = "/agent/login";
export const LOGOUT_ENDPOINT = "/logout";

export const USER_RECOVER_PASSWORD_ENDPOINT = "/user/recover-password";
export const USER_DISCONNECT_OAUTH_ENDPOINT = "/user/disconnect-oauth";

export const DATA_LAYER_ENDPOINT = "/client/data-layer";

export const FRENCH_POSTAL_CODES_ENDPOINT =
  "/location/french-postal-codes?postalCode={postalCodeValue}";
export const FRENCH_COMMUNES_ENDPOINT =
  "/location/french-communes?communeName={communeNameValue}";

export const TERRITORIES_ENDPOINT = "/territories";

export const HOMEPAGE_VOUCHER_ENDPOINT =
  "/client/booking/voucher?voucherToken={tokenValue}&firstJobExecutionDate={executionDateTime}";

// booking
export const BOOKING_AVAILABILITY_ENDPOINT = "/client/booking/availability";
export const BOOKING_AVAILAVILITY_WEEKLY_ENDPOINT =
  "/client/booking/availability/weekly";
export const BOOKING_AVAILAVILITY_CUSTOM_ENDPOINT =
  "/client/booking/availability/custom";
export const BOOKING_PREVIOUS_AGENTS_ENDPOINT =
  "/client/agents?filter={filterValue}&serviceLocationId={serviceLocationIdValue}";
export const BOOKING_PRICING_ENDPOINT = "/client/booking/pricing";
export const BOOKING_PLANS_ENDPOINT =
  "/client/booking/plans?serviceLocationId={serviceLocationIdValue}";
export const BOOKING_PLANS_SERVICE_VOUCHER_ENDPOINT =
  "/client/booking/plans/service-voucher?serviceLocationId={serviceLocationIdValue}";
export const BOOKING_RESERVATION_ENDPOINT = "/client/booking/reservation";
export const BOOKING_VOUCHER_ENDPOINT =
  "/client/booking/voucher?voucherToken={tokenValue}&firstJobExecutionDate={executionDateTime}&frequency={frequencyValue}&pricePlanUuid={pricePlanUuid}";
export const BOOKING_CANCEL_RESERVATION_ENDPOINT =
  "/client/booking/reservation/{reservationUuid}";
export const BOOKING_PAYMENT_METHODS_ENDPOINT =
  "/client/booking/reservation/{reservationUuid}/payment-methods?displayedPrice={displayedPriceValue}";
export const BOOKING_CHECKOUT_ENDPOINT =
  "/client/booking/reservation/{reservationUuid}/checkout";
export const BOOKING_SUBSCRIPTIONS_ENDPOINT = "/client/subscriptions";
export const BOOKING_AVAILABILITY_CONTACT_ENDPOINT =
  "/client/booking/availability/contact";
export const BOOKING_CLEANING_PRODUCTS_ENDPOINT = "/client/products";
export const BOOKING_REGISTER_URSSAF_CLIENT_ENDPOINT =
  "/client/booking/urssaf/client";
export const BOOKING_URSSAF_PRICING_ENDPOINT = "/client/booking/urssaf/pricing";
export const BOOKING_ACCESS_INFORMATION_ENDPOINT =
  "/client/contract/{reservationUuid}/access-information";
export const BOOKING_FREQUENCIES_ENDPOINT =
  "/client/booking/frequencies?serviceLocationId={serviceLocationIdValue}";

// end of tenancy
export const END_OF_TENANCY_WARRANTY_ENDPOINT =
  "/client/end-of-tenancy/warranty";
export const END_OF_TENANCY_AVAILABILITY_ENDPOINT =
  "/client/end-of-tenancy/availability";
export const END_OF_TENANCY_PRICING_ENDPOINT = "/client/end-of-tenancy/pricing";
export const END_OF_TENANCY_RESERVATION_ENDPOINT =
  "/client/end-of-tenancy/reservation";
export const END_OF_TENANCY_VOUCHER_ENDPOINT =
  "/client/end-of-tenancy/voucher?voucherToken={tokenValue}&firstJobExecutionDate={executionDateTime}";
export const END_OF_TENANCY_CHECKOUT_ENDPOINT =
  "/client/end-of-tenancy/reservation/{reservationUuid}/checkout";
export const END_OF_TENANCY_PAYMENT_METHODS_ENDPOINT =
  "/client/end-of-tenancy/reservation/{reservationUuid}/payment-methods?displayedPrice={displayedPriceValue}";

export const CLIENT_REGISTRATION_ENDPOINT = "/client/";
export const VOUCHER_ENDPOINT = "/client/voucher";

export const OAUTH_LOGIN_BY_ACCESS_TOKEN_ENDPOINT =
  "/oauth/login-user-by-access-token";
export const OAUTH_CONNECT_USER_ENDPOINT = "/oauth/connect-with-user";

export const PAYMENT_STATUS_ENDPOINT = "/payment/{referenceNumber}/status";

export const BOOKINGS_TO_VALIDATE_ENDPOINT =
  "/client/booking/bookings-to-validate";
export const BOOKINGS_TO_VALIDATE_PAYMENT_METHODS_ENDPOINT =
  "/client/booking/bookings-to-validate/{uuid}/payment-methods";

export const CONTACT_FORM_ENDPOINT = "/contact";
export const B2B_CONTACT_FORM_ENDPOINT = "/b2b/contact";

export const APPLY_ENDPOINT = "/apply";
export const APPLY_SUBMIT_FILES_ENDPOINT = "/apply/files";
export const APPLY_DELETE_FILES_ENDPOINT = "/apply/files/{fileId}";
export const APPLY_AUTH_ENDPOINT = "/apply/auth";

export const UTM_ENDPOINT = "/utm";

// b2b
export const B2B_LOCATIONS_ENDPOINT = "/client/b2b-booking/locations";
export const B2B_LOCATION_ENDPOINT = "/client/b2b-booking/location";
export const B2B_REGISTER_CLIENT_ENDPOINT = "/client/b2b-booking/client";
export const B2B_REGISTER_COMPANY_ENDPOINT = "/client/b2b-booking/company";
export const B2B_RESERVATION_ENDPOINT = "/client/b2b-booking/reservation";
export const B2B_PAYMENT_METHODS_ENDPOINT =
  "/client/b2b-booking/reservation/{reservationUuid}/payment-methods?displayedPrice={displayedPriceValue}";
export const B2B_PRICING_ENDPOINT = "/client/b2b-booking/pricing";
export const B2B_CHECKOUT_ENDPOINT =
  "/client/b2b-booking/reservation/{b2bReservationUuid}/checkout";
export const B2B_PREMISES_SERVICES_ENDPOINT =
  "/client/b2b-booking/premises-services";
export const B2B_CANCEL_RESERVATION_ENDPOINT =
  "/client/b2b-booking/reservation/{b2bReservationUuid}";
export const B2B_VOUCHER_ENDPOINT =
  "/client/b2b-booking/voucher?voucherToken={tokenValue}&firstJobExecutionDate={executionDateTime}&frequency={frequencyValue}";
export const B2B_AGENTS_ENDPOINT =
  "/client/b2b-booking/agents?filter={filterValue}&serviceLocationId={serviceLocationIdValue}";
export const B2B_FREQUENCIES_ENDPOINT =
  "/client/b2b-booking/frequencies?serviceLocationId={serviceLocationIdValue}";
export const B2B_PLANS_ENDPOINT =
  "/client/b2b-booking/plans?serviceLocationId={serviceLocationIdValue}";
// get a quote (b2b)
export const GET_A_QUOTE_ENDPOINT = "/client/b2b-booking/quote";

// commitment plans
export const SUBSCRIPTION_DETAILS_ENDPOINT =
  "/client/subscriptions/{subscriptionUuid}";

// gift cards
export const GIFT_CARDS_PAYMENT_METHODS_ENDPOINT =
  "/gift-cards/payment-methods";
export const GIFT_CARDS_CHECKOUT_ENDPOINT = "/gift-cards/checkout";

// Feature toggles
export const FEATURE_TOGGLES_ENDPOINT = "/feature/public";

// Agent profile
export const AGENT_PROFILE_ENDPOINT = "/agent/profile";
export const AGENT_BANK_ACCOUNT_ENDPOINT = "/agent/profile/bank-account";
export const AGENT_CHANGE_PASSWORD_ENDPOINT = "/agent/profile/password";
export const AGENT_WORK_PERMITS_ENDPOINT = "/agent/dictionary/work-permits";

// Agent schedule
export const AGENT_SCHEDULE_ENDPOINT = "/agent/schedule?startDate={startDate}";

// Agent jobs
export const AGENT_JOB_BY_UUID_ENDPOINT = "/agent/jobs/{jobUuid}";
export const AGENT_JOB_CONFIRM_ENDPOINT = "/agent/jobs/{jobUuid}/confirm";
export const AGENT_JOB_DECLINE_ENDPOINT = "/agent/jobs/{jobUuid}/decline";
export const AGENT_JOB_CANNOT_MAKE_ENDPOINT =
  "/agent/jobs/{jobUuid}/cannot-make";

// Agent availabilities
export const AGENT_WORK_HOURS_ENDPOINT = "/agent/work-hours";
export const AGENT_WORK_HOURS_BY_UUID_ENDPOINT =
  "/agent/work-hours/{workHoursUuid}";
export const AGENT_ABSENCES_ENDPOINT = "/agent/absences";
export const AGENT_ABSENCE_BY_UUID_ENDPOINT = "/agent/absences/{absenceUuid}";

export const AGENT_JOBS_AFFECTED_BY_ADD_ABSENCE_ENDPOINT =
  "/agent/absences/jobs-affected-by-add";
export const AGENT_JOBS_AFFECTED_BY_CHANGE_ABSENCE_ENDPOINT =
  "/agent/absences/{absenceUuid}/jobs-affected-by-change";

export const AGENT_WORK_HOURS_AFFECTED_BY_CHANGE_ENDPOINT =
  "/agent/work-hours/{workHoursUuid}/jobs-affected-by-change";
export const AGENT_WORK_HOURS_AFFECTED_BY_DELETE_ENDPOINT =
  "/agent/work-hours/{workHoursUuid}/jobs-affected-by-delete";

// Agent cleanings
export const AGENT_CLEANINGS_ENDPOINT =
  "/agent/cleanings?timePeriod={timePeriodValue}";
export const AGENT_CLEANINGS_HISTORY_EDNPOINT =
  "/agent/cleanings/history?page={pageValue}";
export const AGENT_CLEANINGS_PROPOSALS_ENDPOINT =
  "/agent/proposals?timePeriod={timePeriodValue}";
export const AGENT_CLEANINGS_PROPOSALS_COUNT_ENDPOINT =
  "/agent/proposals/count";

// Agent change request
export const AGENT_CHANGE_REQUESTS_ENDPOINT =
  "/agent/change-requests?timePeriod={timePeriodValue}";
export const AGENT_CHANGE_REQUEST_BY_UUID_ENDPOINT =
  "/agent/change-requests/{changeRequestUuid}";
export const AGENT_CHANGE_REQUEST_ACCEPT_ENDPOINT =
  "/agent/change-requests/{changeRequestUuid}/accept";
export const AGENT_CHANGE_REQUEST_DECLINE_ENDPOINT =
  "/agent/change-requests/{changeRequestUuid}/decline";
export const AGENT_CHANGE_REQUEST_COUNT_ENDPOINT =
  "/agent/change-requests/count";

// Agent Checking
export const AGENT_CHECKIN_ENDPOINT = "/agent/jobs/{jobUuid}/checkin";
export const AGENT_CHECKOUT_ENDPOINT = "/agent/jobs/{jobUuid}/checkout";

export const STATES_ENDPOINT = "/resources/states";

// Cleaning recap / summary
export const USER_CLEANING_RECAP_PERIODS_ENDPOINT =
  "/client/cleanings/recap/periods";
export const USER_CLEANING_RECAP_YEARLY_ENDPOINT =
  "/client/cleanings/recap/year/{year}";
export const USER_CLEANING_RECAP_MONTHLY_ENDPOINT =
  "/client/cleanings/recap/month/{year}/{month}";

/**
 * date-fns format `yyyy-MM-dd`
 */
export const USER_CLEANING_RECAP_CUSTOM_ENDPOINT =
  "/client/cleanings/recap/custom/{from}/{to}";
