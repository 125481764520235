import * as React from "react";

import { useInitProvider } from "./DrawerManager.hooks";
import { ContextProps } from "./DrawerManager.types";

export const DrawerContext = React.createContext<ContextProps | null>(null);

const DrawerManagerProvider = (
  props: React.PropsWithChildren<any>,
): React.ReactElement => {
  const context = useInitProvider();

  return (
    <DrawerContext.Provider value={context}>
      {props.children}
    </DrawerContext.Provider>
  );
};

export { DrawerManagerProvider };
